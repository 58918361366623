<template>
    <div class="wrap_maps_events">
        <div class="maps_even_list">
            <div class="change_view_events">
                <button type="button" class="_map" :class="{'active': viewEvents ==='map'}" @click="changeViewAlbums('map')"></button>
                <button type="button" class="_list" :class="{'active': viewEvents ==='list'}" @click="changeViewAlbums('list')"></button>
            </div>

            <div class="skeleton-loader" v-if="eventsLoading">
                <v-skeleton-loader
                    v-for="index in 6"
                    :key="index"
                    type="image"
                    class="map_events">
                </v-skeleton-loader>
            </div>
            <template v-if="!eventsLoading">
                <div class="maps_event"
                     v-for="(event, index) of events" :key="event.id"
                     @mouseover="setMouseoverMarker(event, index)"
                     @mouseleave="setMouseleaveMarker(event, index)"
                     @click="redirectToEvent(event.id)"
                     :class="{'active': event.id === activeEventsID}">

                    <div class="cover_image">
                        <template v-if="event.images[0] && event.images[0].contentUrl">
                            <img :src="apiUrl.slice(0, -1) + `${event.images[0].contentUrl}`" alt="photo">
                        </template>
                        <template v-else>
                            <i>no image</i>
                        </template>
                    </div>
                    <div class="small_info">
                        <router-link :to="{name: 'eventDetails', params: {id: event.id}}" class="name">{{ event.title }}</router-link>

                        <div class="location">{{ JSON.parse(event.location).locality }}, {{ JSON.parse(event.location).country }}</div>

                        <div class="date">{{ event.dateStart | moment("MMMM DD, YYYY") }}</div>
                        <div class="duration">
                            <!--                                                            {{event.dateEnd.diff(event.dateStart)}}-->
                            in {{ event.dateEnd | moment('from', event.dateStart, true) }}
                            <!--                            in 3 Weeks 2 Days-->
                        </div>
                    </div>
                </div>
            </template>

            <div class="no_events_found" v-if="!eventsLoading && !events.length">No events found</div>
        </div>

        <div class="wrap_map">
            <div class="switch_map_calendar" @click="changeSwitch">
                <div class="calendar">Calendar</div>
                <div class="map">Map</div>
                <button type="button" class="switch_active" :class="switchActive">{{ switchActive }}</button>
            </div>
            <div class="google_map" ref="googleMap"></div>
        </div>
    </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
import moment from 'moment';

export default {
    name: "mapEvents",
    props: ['events'],
    data() {
        return {
            apiUrl: process.env.VUE_APP_API_URL,

            // google maps
            map: null,
            bounds: new google.maps.LatLngBounds(),
            mapOptions: {
                // center: {lat: 35.88147, lng: -100.06102},
                zoomControl: true,
                zoom: 4,
                gestureHandling: 'cooperative',
                styles: [
                    {
                        "featureType": "all",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#202c3e"
                            },
                        ]
                    },
                    {
                        "featureType": "all",
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "gamma": 0.01
                            },
                            {
                                "lightness": 20
                            },
                            {
                                "weight": "1.39"
                            },
                            {
                                "color": "#ffffff"
                            },
                            // {
                            //     "visibility": "off"
                            // },
                        ]
                    },
                    {
                        "featureType": "all",
                        "elementType": "labels.text.stroke",
                        "stylers": [
                            {
                                "weight": "0.96"
                            },
                            {
                                "saturation": "9"
                            },
                            {
                                "visibility": "on"
                            },
                            {
                                "color": "#000000"
                            }
                        ]
                    },
                    {
                        "featureType": "all",
                        "elementType": "labels.icon",
                        "stylers": [
                            {
                                "visibility": "off" // off
                            }
                        ]
                    },
                    {
                        "featureType": "landscape",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "lightness": 30
                            },
                            {
                                "saturation": "9"
                            },
                            {
                                "color": "#29446b"
                            },
                        ]
                    },
                    {
                        "featureType": "poi",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "saturation": 20
                            },
                            {
                                "visibility": "off"
                            },
                        ]
                    },
                    {
                        "featureType": "poi.park",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "lightness": 20
                            },
                            {
                                "saturation": -20
                            },
                            {
                                "visibility": "off"
                            },
                        ]
                    },
                    {
                        "featureType": "road",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "lightness": 10
                            },
                            {
                                "saturation": -30
                            }
                        ]
                    },
                    {
                        "featureType": "road",
                        "elementType": "geometry.fill",
                        "stylers": [
                            // {
                            //     "color": "#193a55"
                            // },
                            {
                                "visibility": "off"
                            },

                        ]
                    },
                    {
                        "featureType": "road",
                        "elementType": "geometry.stroke",
                        "stylers": [
                            {
                                "saturation": 25
                            },
                            {
                                "lightness": 25
                            },
                            {
                                "weight": "0.01"
                            },
                        ]
                    },
                    {
                        "featureType": "water",
                        "elementType": "all",
                        "stylers": [
                            {
                                "lightness": -20
                            },
                        ]
                    }
                ]
            },
            svgMarker: {
                path: "M12.0007 16.1666C10.8956 16.1666 9.83577 15.7276 9.05437 14.9462C8.27297 14.1648 7.83398 13.105 7.83398 11.9999C7.83398 10.8948 8.27297 9.83504 9.05437 9.05364C9.83577 8.27224 10.8956 7.83325 12.0007 7.83325C13.1057 7.83325 14.1655 8.27224 14.9469 9.05364C15.7283 9.83504 16.1673 10.8948 16.1673 11.9999C16.1673 12.5471 16.0595 13.0889 15.8502 13.5944C15.6408 14.1 15.3338 14.5593 14.9469 14.9462C14.56 15.3331 14.1007 15.64 13.5952 15.8494C13.0896 16.0588 12.5478 16.1666 12.0007 16.1666ZM12.0007 0.333252C8.90646 0.333252 5.939 1.56241 3.75107 3.75034C1.56315 5.93826 0.333984 8.90573 0.333984 11.9999C0.333984 20.7499 12.0007 33.6666 12.0007 33.6666C12.0007 33.6666 23.6673 20.7499 23.6673 11.9999C23.6673 8.90573 22.4382 5.93826 20.2502 3.75034C18.0623 1.56241 15.0948 0.333252 12.0007 0.333252Z",
                fillColor: "#20C4F5",
                fillOpacity: 1,
                strokeWeight: 0,
                rotation: 0,
                scale: 1.1,
                anchor: new google.maps.Point(32, 65),
                scaledSize: new google.maps.Size(80, 80),
                origin: new google.maps.Point(0, 0),
                labelOrigin: new google.maps.Point(40, 33),
            },
            activeSvgMarker: {
                path: "M12.0007 16.1666C10.8956 16.1666 9.83577 15.7276 9.05437 14.9462C8.27297 14.1648 7.83398 13.105 7.83398 11.9999C7.83398 10.8948 8.27297 9.83504 9.05437 9.05364C9.83577 8.27224 10.8956 7.83325 12.0007 7.83325C13.1057 7.83325 14.1655 8.27224 14.9469 9.05364C15.7283 9.83504 16.1673 10.8948 16.1673 11.9999C16.1673 12.5471 16.0595 13.0889 15.8502 13.5944C15.6408 14.1 15.3338 14.5593 14.9469 14.9462C14.56 15.3331 14.1007 15.64 13.5952 15.8494C13.0896 16.0588 12.5478 16.1666 12.0007 16.1666ZM12.0007 0.333252C8.90646 0.333252 5.939 1.56241 3.75107 3.75034C1.56315 5.93826 0.333984 8.90573 0.333984 11.9999C0.333984 20.7499 12.0007 33.6666 12.0007 33.6666C12.0007 33.6666 23.6673 20.7499 23.6673 11.9999C23.6673 8.90573 22.4382 5.93826 20.2502 3.75034C18.0623 1.56241 15.0948 0.333252 12.0007 0.333252Z",
                fillColor: "#FF6523",
                fillOpacity: 1,
                strokeWeight: 0,
                rotation: 0,
                scale: 1.1,
                anchor: new google.maps.Point(32, 65),
                scaledSize: new google.maps.Size(80, 80),
                origin: new google.maps.Point(0, 0),
                labelOrigin: new google.maps.Point(40, 33),
            },
            activeInfoWindow: null,
            activeEventsID: null,
            markersList: [],
            mapInfoWindow: null,

            switchActive: 'Map',
        }
    },
    watch: {

    },
    computed: {
        ...mapGetters({
            eventsList: 'events/EVENTS',
            viewEvents: 'events/VIEW_EVENTS',
            eventsLoading: 'events/EVENTS_LOADING',
        })
    },
    created() {
        // window.redirectToEvent = function (eventID) {
        //     console.log(this.$route);
        //     // this.$router.push({name: 'eventDetails', params: {id: eventID}});
        //     // console.log(111);
        // }
    },

    mounted() {
        let _self = this;
        window.redirectToEvent = function (eventID) {
            console.log('eventID', eventID);
            _self.$router.push({name: 'eventDetails', params: {id: eventID}});
        }

        // Load required libraries and initialize map
        this.loadGoogleMaps();
    },
    methods: {
        changeSwitch() {
            this.switchActive = this.switchActive === 'Map' ? 'Calendar' : 'Map';
        },
        ...mapActions({
            updateViewAlbums: 'events/UPDATE_VIEW_EVENTS',
            getEvents: 'events/GET_EVENTS'
        }),
        setActiveEvent(event, index) {
            google.maps.event.trigger(this.markersList[index], 'click');
        },
        setMouseoverMarker(event, index) {
            google.maps.event.trigger(this.markersList[index], 'mouseover');
        },
        setMouseleaveMarker(event, index) {
            google.maps.event.trigger(this.markersList[index], 'mouseleave');
        },

        async loadGoogleMaps() {
            try {
                const { Map } = await google.maps.importLibrary("maps");
                const { Places } = await google.maps.importLibrary("places");
                window.googleMapsLoaded = true;
                this.initializeMap();
            } catch (error) {
                console.error("Error loading Google Maps:", error);
            }
        },
        initializeMap() {
            if (!this.events.length) {
                this.getEvents().then(() => {
                    this.initMap();
                })
            } else {
                this.initMap();
            }
        },
        initMap() {
            const mapContainer = this.$refs.googleMap;

            // create map
            this.map = new google.maps.Map(mapContainer, {
                ...this.mapOptions
            });


            for (let i = 0; i < this.events.length; i++) {
                let latitude = JSON.parse(this.events[i].location).latitude;
                let longitude = JSON.parse(this.events[i].location).longitude;
                let position = {lat: latitude, lng: longitude};

                let marker = new google.maps.Marker({
                    position: position,
                    map: this.map,
                    icon: this.svgMarker,
                });
                this.markersList.push(marker);

                this.bounds.extend(this.markersList[i].getPosition());



                marker.addListener('mouseover', () => {
                    for (let j = 0; j < this.markersList.length; j++) {
                        this.markersList[j].setIcon(this.svgMarker);
                    }
                    marker.setIcon(this.activeSvgMarker);
                });
                marker.addListener('mouseout', () => {
                    // marker.setIcon(this.svgMarker);
                });
                marker.addListener('mouseleave', () => {
                    // marker.setIcon(this.svgMarker);
                });



                marker.addListener("click", () => {
                    this.activeEventsID = this.events[i].id;

                    if (this.activeInfoWindow !== null) {
                        this.activeInfoWindow.close();
                    }


                    const eventContent = `<div class="info_window_marker" id="info-window" onclick="redirectToEvent(${this.events[i].id})">
                                            <div class="event_img">
                                                <img src="${this.apiUrl.slice(0, -1)}${this.events[i].images[0].contentUrl}" alt="photo">
                                            </div>
                                            <div class="marker_info_event">
                                                <div class="name_event">${this.events[i].title}</div>
                                                <div class="location">${JSON.parse(this.events[i].location).locality}, ${JSON.parse(this.events[i].location).country}</div>
                                                <div class="date">${moment(this.events[i].dateStart).format('dd, MMM DD')} ${moment(this.events[i].dateStart).format('HH:mm')} EDT</div>
                                                <div class="genres">
                                                    ${this.events[i].genre[0]},
                                                    ${this.events[i].genre[1]}
                                                    and more
                                                </div>
                                            </div>
                                        </div>`;
                    this.mapInfoWindow = new google.maps.InfoWindow({
                        content: eventContent,
                    });

                    this.map.panTo(marker.getPosition());
                    this.mapInfoWindow.open(this.map, marker);


                    for (let j = 0; j < this.markersList.length; j++) {
                        this.markersList[j].setIcon(this.svgMarker);
                    }
                    marker.setIcon(this.activeSvgMarker);


                    this.activeInfoWindow = this.mapInfoWindow;

                });


                // this.map.addListener("center_changed", () => {
                //     if (this.activeInfoWindow !== null) this.activeInfoWindow.close();
                //     for (let j = 0; j < this.markersList.length; j++) {
                //         this.markersList[j].setIcon(this.svgMarker);
                //     }


                    // let button = document.getElementsByClassName('iw-close-btn');
                    // console.log('button', button);
                    // button[0].addListener('click', function() {
                    //    console.log(111);
                    // });
                    // let button2 = document.getElementsByClassName('info_window_marker');
                    // console.log('button2', button2);
                    // console.log('center_changed');
                    // 3 seconds after the center of the map has changed, pan back to the
                    //
                    // window.setTimeout(() => {
                    //     this.map.panTo(marker.getPosition() );
                    // }, 3000);
                // });
            }


            this.map.fitBounds(this.bounds);
        },

        changeViewAlbums(view) {
            this.updateViewAlbums(view);
        },
        redirectToEvent(eventID) {
            this.$router.push({name: 'eventDetails', params: {id: eventID}});
        }
    }
}
</script>

<style lang="scss">
.gm-style-iw-d {
    overflow: hidden !important;
}

.gm-style-iw-tc {
    display: none;
}

.gm-ui-hover-effect { // infoWindow close btn
    top: 0 !important;
    right: 0 !important;
    display: none !important;
    > span {
        background-color: #fff;
    }
}

.gm-style .gm-style-iw-c {
    background-color: #282C47;
    border-radius: 12px;
    padding: 0;
    width: 245px;
    height: 215px;
    transform: translate3d(-50%, 25%, 0);
}

.info_window_marker {

    .event_img {
        width: 100%;
        height: 100px;
        overflow: hidden;

        > img {

        }
    }

    .marker_info_event {
        padding: 10px 8px 15px 8px;

        > div {
            line-height: 150%;
        }

        .name_event {
            font-size: 16px;
            font-weight: 500;
            color: #dcdcdc;
            margin-bottom: 2px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .location {
            color: #aaa;
            font-size: 14px;
            font-weight: 500;
        }

        .date {
            color: #aaa;
            font-size: 12px;
            font-weight: 500;
            text-transform: uppercase;
        }

        .genres {
            font-size: 14px;
            font-weight: 400;
            color: #7481A6;
        }
    }

}
</style>